<template>
  <div class="nvx-app-container">

    <div>
      <h2 class="sdc-title">
        {{ $t("account_settings_title") | capitalize }}
      </h2>
      <p class="sdc-description">
        {{ $t("account-settings_description") }}
      </p>
    </div>

    <nvx-list>

      <nvx-section>
        <nvx-button-cell :target="subscriptionInterceptable" :icon="null">
          <template v-slot:default>
            {{ $t("manage_your_subscription") | capitalize }}
          </template>
          <template v-slot:description>
            {{ $t("view_and_renew_your_subscription") | capitalize }}
          </template>
        </nvx-button-cell>
      </nvx-section>

      <nvx-section>
        <nvx-router-cell to="/account_profil_update">
          <template v-slot:default>
            {{ $t("profile") | capitalize }}
          </template>
          <template v-slot:description>
            {{ getUserSetting ? getUserSetting.firstname : '' }}
            {{ getUserSetting ? getUserSetting.lastname : '' }}
          </template>
        </nvx-router-cell>
      </nvx-section>

      <nvx-section>
        <nvx-router-cell to="/account_email_update">
          <template v-slot:default>
            {{ $t("manage_your_mail") | capitalize }}
          </template>
          <template v-slot:description>
            {{ getUserName }}
          </template>
        </nvx-router-cell>
      </nvx-section>

      <nvx-section v-if="isAndroid">
        <nvx-button-cell :target="logout" :icon="null">
          {{ $t("logout") | capitalize }}
        </nvx-button-cell>
      </nvx-section>

      <nvx-section style="padding-top: 1.5rem;">
        <nvx-router-cell to="/account_delete" :destructiveStyle=true>
          {{ $t("delete_your_account") | capitalize }}
        </nvx-router-cell>
      </nvx-section>
      
    </nvx-list>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import NvxSection from "@/components/ui/NvxSection.vue";
import NvxList from "@/components/ui/NvxList.vue";
import NvxButtonCell from "@/components/ui/NvxCell.vue";
import NvxRouterCell from "@/components/ui/NvxRouterCell.vue";

export default {
  name: "AccountSettings",

  components: { 
    NvxSection,
    NvxList,
    NvxButtonCell,
    NvxRouterCell,
   },

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters("users", ["getUserName", "getUserSetting"]),
    ...mapGetters("appcompat", ["isVerifyNative"]),
    isAndroid() {
      return /Android/.test(navigator.userAgent);
    },
    isV2() {
      return this.isVerifyNative
    }
  },

  methods: {
    ...mapActions("users", ["setToken", "fetchUserData", "fetchUserSetting"]),
    ...mapActions("appcompat", ["setIsVerifyNative"]),

    setV2(value) {
      this.setIsVerifyNative(value)
    },
    logout() {
      window.location.href = '/logout/';
    },
    subscriptionInterceptable() {
      window.location.href = '/subscription?webview=true';
    },


  },

  async mounted() {
    await this.fetchUserData();
    await this.fetchUserSetting();
    window.zE("webWidget", "hide");
  },
  
  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }
    let routeParams = this.$route.params
    if (routeParams["pageVersion"]) {
      let pageVersion = routeParams["pageVersion"]
      this.setV2( pageVersion != "v1")
    }
    
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";

</style>